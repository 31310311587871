import React from 'react';
import '../../App.css';
import Cards from '../WorkTermTwo';
import Footer from '../Footer';

function WorkTermPageTwo(){
    return(
        <>
            <Cards />
            <Footer />
        </>
    );
}

export default WorkTermPageTwo;